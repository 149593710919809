$('#newsletter-form').submit(function(event){

    $('.loading-icon').show();

    event.preventDefault();

    var form = $(this);
    var method = form.find('input[name="_method"]').val() || 'POST';
    var url = form.prop('action');

    $.ajax({
        type: method,
        url: url,
        data: form.serialize(),
        success: function(data){
            $('.loading-icon').hide();
            if(data.error == false) {
                $('.messages-newsletter').append('<div class="alert alert-success">'+data.results.data+'</div>').delay('4000').slideUp('400', function(){
                    $(this).empty().hide();
                });
            } else {
                $('.messages-newsletter').append('<div class="alert alert-danger">'+data.error.message+'</div>').delay('4000').slideUp('400', function(){
                    $(this).empty().show();
                });
            }

        }
    });

});